import "design/introduceCss/way.css";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
declare global {
  interface Window {
    google: any;
  }
}

const Way = () => {
  const { t } = useTranslation();
  const language = useSelector((state: any) => state?.language?.language);
  const mapRef = useRef(null);

  const initMap = useCallback(() => {
    // 지도 생성
    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat: 36.4271206, lng: 127.3947981 },
      zoom: 16,
    });

    // 마커 생성
    new window.google.maps.Marker({
      position: { lat: 36.4271206, lng: 127.3947981 },
      map: map,
      title: "여기입니다!",
    });
  }, [mapRef]);

  useEffect(() => {
    initMap();
  }, [initMap]);

  return (
    <div className={"way-container"}>
      <div className={"way-title"}>
        <h1>{t("menu.way")}</h1>
      </div>
      <div className={"way-content"}>
        <div className="map google-map" ref={mapRef}></div>
        <div className={"way-content-text"}>
          <div className={"title"}>
            <p>{t("way.address")}</p>
            <span>{t("overview.address")}</span>
          </div>
          <div className={"description"}>
            <span>{t("way.transportation")}</span>
            <div className={"description-content"}>
              <p>{t("way.sintanjin")}</p>
              <p>{t("way.northDaejeon")}</p>
              <p>{t("way.yuseong")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Way;
