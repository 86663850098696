import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import "design/LayoutCss/Drawer.css";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "redux/actions";
import { useDispatch } from "react-redux";

type Anchor = "top" | "left" | "bottom" | "right";

export const CustomDrawer = (props: any) => {
  const { toggleDrawer, state, isLanguageSelected, setLanguage } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    >
      <div className={"d-flex justify-content-between m-2 align-items-center"}>
        <div className={"drawer-language"}>
          <button
            onClick={async () => {
              dispatch(await changeLanguage("change", "ko"));
            }}
            style={{
              textDecoration: isLanguageSelected("ko") ? "underline" : "none",
            }}
          >
            KO
          </button>
          <button
            onClick={async () => {
              dispatch(await changeLanguage("change", "en"));
            }}
            style={{
              textDecoration: isLanguageSelected("en") ? "underline" : "none",
            }}
          >
            EN
          </button>
        </div>
        <span
          className="btn-close"
          onClick={toggleDrawer(anchor, false)}
          key={anchor}
        />
      </div>

      <div className={"main-title "}>
        <span> {t(`header.introduce`)}</span>
        <div className={"sub-title row gap-2"}>
          <a href={"/about/youngit"}>{t("menu.overview")}</a>
          <a href={"/about/organization"}>{t("menu.organization")}</a>
          <a href={"/about/way"}>{t("menu.way")}</a>
        </div>
        <span> {t(`header.business`)}</span>
        <div className={"sub-title row gap-2"}>
          <a href={"/business/smart-check"}>{t("menu.smartCheck")}</a>
        </div>
        <span> {t(`header.product`)}</span>
        <div className={"sub-title row gap-2"}>
          <a href={"/product/software"}>{t("menu.software")}</a>
          <a href={"/product/hardware"}>{t("menu.hardWare")}</a>
        </div>
        <span> {t(`header.reference`)}</span>
        <div className={"sub-title row gap-2"}>
          <a href={"/reference/brochure"}>{t("menu.brochure")}</a>
          <a href={"/reference/certification"}>{t("menu.certification")}</a>
        </div>
        <span> {t(`header.community`)}</span>
        <div className={"sub-title row gap-2"}>
          <a href={"/community/notice"}>{t("menu.notice")}</a>
          <a href={"/community/recruit"}>{t("menu.recruit")}</a>
          <a href={"/community/guide"}>{t("menu.guide")}</a>
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </div>
  );
};
