import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import "design/LayoutCss/header.css";
import { CustomDrawer } from "Layout/components/Drawer";
import i18n from "locales/i18n";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "redux/actions";

const Header = () => {
  const { t } = useTranslation();
  const language = useSelector((state: any) => state?.language?.language);
  const [state, setState] = React.useState({
    right: false,
  });
  const dispatch = useDispatch();
  const toggleDrawer =
    (anchor: string, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
  useEffect(() => {
    const header: any = document.getElementById("header");

    const handleScroll = () => {
      if (window.pageYOffset >= 140) {
        header.classList.add("active");
      } else {
        header.classList.remove("active");
      }
    };

    window.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const isLanguageSelected = (lang: string) => {
    return language === lang;
  };
  return (
    <header id={"header"} className={`header-container `}>
      <h1 className={"logo"}>
        <a href={"/"}>young-it</a>
      </h1>
      <div className={"language"}>
        <button
          onClick={async () => {
            dispatch(await changeLanguage("change", "ko"));
          }}
          style={{
            textDecoration: isLanguageSelected("ko") ? "underline" : "none",
          }}
        >
          KO
        </button>
        <button
          onClick={async () => {
            dispatch(await changeLanguage("change", "en"));
          }}
          style={{
            textDecoration: isLanguageSelected("en") ? "underline" : "none",
          }}
        >
          EN
        </button>
        {/* <button
          onClick={async () => {
            dispatch(await changeLanguage("change", "jp"));
          }}
          style={{
            textDecoration: isLanguageSelected("jp") ? "underline" : "none",
          }}
        >
          JP
        </button> */}
      </div>
      <nav className={"header-nav"}>
        <ul className={"menu"}>
          <li className={"menu-item"}>
            <a
              href={"/about/youngit"}
              style={{
                pointerEvents: "none",
              }}
            >
              {t("header.introduce")}
            </a>
          </li>
          <li className={"menu-item"}>
            <a
              href={"/business/smart-check"}
              style={{
                pointerEvents: "none",
              }}
            >
              {" "}
              {t("header.business")}
            </a>
          </li>
          <li className={"menu-item"}>
            <a
              href={"/product/software"}
              style={{
                pointerEvents: "none",
              }}
            >
              {" "}
              {t("header.product")}
            </a>
          </li>
          <li className={"menu-item"}>
            <a
              href={"/reference/brochure"}
              style={{
                pointerEvents: "none",
              }}
            >
              {" "}
              {t("header.reference")}
            </a>
          </li>
          <li className={"menu-item"}>
            <a
              href={"/community/notice"}
              style={{
                pointerEvents: "none",
              }}
            >
              {" "}
              {t("header.community")}
            </a>
          </li>
        </ul>
        <div className={"sub-menu-container"}>
          {/*<div*/}
          {/*  className={"d-flex justify-content-evenly w-100"}*/}
          {/*  style={{ position: "relative", top: "14%" }}*/}
          {/*>*/}
          <div className={"sub-menu-container-items"}>
            {/*</div>*/}
            <ul className={"sub-menu"}>
              <li>
                <a href={"/about/youngit"}>{t("menu.overview")}</a>
              </li>
              <li>
                <a href={"/about/organization"}>{t("menu.organization")}</a>
              </li>
              <li>
                <a href={"/about/way"}>{t("menu.way")}</a>
              </li>
            </ul>
            <ul className={"sub-menu"}>
              <li>
                <a href={"/business/smart-check"}>{t("menu.smartCheck")}</a>
              </li>
            </ul>
            <ul className={"sub-menu"}>
              <li>
                <a href={"/product/software"}>{t("menu.software")}</a>
              </li>
              <li>
                <a href={"/product/hardware"}>{t("menu.hardWare")}</a>
              </li>
            </ul>
            <ul className={"sub-menu"}>
              <li>
                <a href={"/reference/brochure"}>{t("menu.brochure")}</a>
              </li>
              <li>
                <a href={"/reference/certification"}>
                  {t("menu.certification")}
                </a>
              </li>
            </ul>

            <ul className={"sub-menu"}>
              <li>
                <a href={"/community/notice"}>{t("menu.notice")}</a>
              </li>
              <li>
                <a href={"/community/recruit"}>{t("menu.recruit")}</a>
              </li>
              <li>
                <a href={"/community/guide"}>{t("menu.guide")}</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className={"all-menu-btn"}>
        <MenuOpenIcon className={""} onClick={toggleDrawer("right", true)} />
        <div className={"header-drawer"}>
          <CustomDrawer
            toggleDrawer={toggleDrawer}
            state={state}
            isLanguageSelected={isLanguageSelected}
          />
        </div>
      </div>
    </header>
  );
};
export default Header;
